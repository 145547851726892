$form-textcolor: #A8A3A3 !default;
$grey-color: #f2f2f2 !default;
$dark-grey-color: #191d1d !default;
$black-color: #000 !default;
$white-color: #fff !default;
$green-color: #13a983 !default;
$dark-green-color: #008F70 !default;
$turquoise-color: #4DC3A9 !default;

$dark-grey: #3c3c3b !default;

$colors:	("green", $green-color),
		("grey", $grey-color),
		("dark-green-color", $dark-green-color),
		("turquoise-color", $turquoise-color),
		("black", $black-color),
		("white", $white-color),
		("dark-grey", $dark-grey-color),
		("dark-grey2", $dark-grey),
		("form-textcolor", $form-textcolor);

@each $classname, $color in $colors {
	.color-#{$classname} {
		color: $color;
	}
}
