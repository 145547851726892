$footer--background-color: $dark-grey-color !default;
$footer__container--padding-top: 0px !default;
$footer__container--padding-bottom: 0px !default;
$footer__figure--margin-left: 5px !default;
$footer__container-p--margin-bottom: 0px !default;
$footer__container-p--color: $green-color !default;
$footer__container-p--padding-top: 20px !default;
$footer__container-a--color: $green-color !default;
$footer__container-a--border-left: 2px solid $green-color !default;
$footer__container-a--padding-left: 15px !default;
$footer__container-a--margin-left: 15px !default;


@include block(footer){
	background-color: $footer--background-color;
	min-height: 72px;

	@include element(container){
		padding-top: $footer__container--padding-top;
		padding-bottom: $footer__container--padding-bottom;

		.neos-contentcollection{
			.neos-nodetypes-image{
				figure{
					margin-left: $footer__figure--margin-left;
					float: left;
					display: inline-block;
					position: relative;
					left: 68%;

					@media (max-width: $md){
						left: 45%;
					}

					@media (max-width: $sm){
						left: 0px;
						margin-left: 0px;
						margin-top: 15px;
					}

					a{
						outline: none;
					}
				}
			}
			.neos-nodetypes-text{
				float: left;

				@media (max-width: $sm){
					float: none;
				}
			}
		}
	}

	p{
		margin-bottom: $footer__container-p--margin-bottom;
		color: $footer__container-p--color;
		padding-top: $footer__container-p--padding-top;
	}

	a{
		color: $footer__container-a--color;
		border-left: $footer__container-a--border-left;
		padding-left: $footer__container-a--padding-left;
		margin-left: $footer__container-a--margin-left;
		text-decoration: none;

		@media (max-width: $sm){
			padding-left: 0px;
			margin-left: 0px;
			border-left: none;
		}

		&:hover{
			text-decoration: underline;
		}
	}

	figure{

		a{
			border-left: none;
			padding-left: 0px;
		}
	}
}