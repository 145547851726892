$path: "../../../../Static/Packages/Mobilistics.Site/Fonts/" !default;

@font-face {
	font-family: 'mobilistics-icons';
	src:
		url($path + 'mobilistics-icons.woff2') format('woff2'),
		url($path + 'mobilistics-icons.woff') format('woff');
	font-style: normal;
	font-weight: 400;
	font-display: block;
}

.mobilistics-icon {
	display: inline-block;
	font-family: 'mobilistics-icons';
	font-weight: 400;
	font-style: normal;
	font-size: inherit;
	text-rendering: geometricPrecision;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	speak: none;
}

$icons: (
	'check': '\f00c',
	'close': '\f00d',
	'chevron-left': '\f053',
	'chevron-right': '\f054',
	'twitter': '\f099',
	'facebook': '\f09a',
	'bars': '\f0c9',
	'angle-up': '\f106',
	'angle-down': '\f107',
	'circle': '\f111',
	'home': '\e900'
) !default;

@each $name, $code in $icons {
	.mobilistics-icon--#{$name}::before {
		content: $code;
	}
}
$icon-check: "\f00c" !default;
$icon-close: "\f00d" !default;
$icon-chevron-left: "\f053" !default;
$icon-chevron-right: "\f054" !default;
$icon-twitter: "\f099" !default;
$icon-facebook: "\f09a" !default;
$icon-bars: "\f0c9" !default;
$icon-angle-up: "\f106" !default;
$icon-angle-down: "\f107" !default;
$icon-circle: "\f111" !default;
$icon-home: "\e900" !default;
