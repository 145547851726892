$logo--width: 300px !default;
$logo--height: 200px !default;
$logo--height-lg: 170px !default;
$logo--height-md: 150px !default;
$logo--height-sm: 120px !default;

@include block(logo) {
	position: absolute;
	left: calc(100% / 2 - 150px);
	width: $logo--width;
	height: $logo--height;
	z-index: 2;
	background: url(../Images/logo.svg) no-repeat;
	background-size: 100% 100%;
	background-color: #fff !important;

	@media (max-width: $lg) {
		height: 170px;
	}

	@media (max-width: $md) {
		height: 150px;
	}

	@media (max-width: $sm) {
		height: 120px;
		width: 165px;
		left: 25%;
	}
}
