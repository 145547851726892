@mixin clearfix() {
	&:before,
	&:after {
		content: " ";
		display: table;
	}
	&:after {
		clear: both;
	}
}

/** Prefix Settings **/
$prefix-for-spec: true !default;
$prefix-for-webkit:    true !default;
$prefix-for-mozilla:   true !default;
$prefix-for-microsoft: true !default;
$prefix-for-opera:     true !default;

@mixin prefixer ($property, $value, $prefixes: webkit moz ms o spec) {
	@each $prefix in $prefixes {
		@if $prefix == webkit {
			@if $prefix-for-webkit {
				-webkit-#{$property}: $value;
			}
		}
		@else if $prefix == moz {
			@if $prefix-for-mozilla {
				-moz-#{$property}: $value;
			}
		}
		@else if $prefix == ms {
			@if $prefix-for-microsoft {
				-ms-#{$property}: $value;
			}
		}
		@else if $prefix == o {
			@if $prefix-for-opera {
				-o-#{$property}: $value;
			}
		}
		@else if $prefix == spec {
			@if $prefix-for-spec {
				#{$property}: $value;
			}
		}
		@else  {
			@warn "Unrecognized prefix: #{$prefix}";
		}
	}
}

@mixin borderbox() {
	@include prefixer(box-sizing, border-box);
}

/** BEM Mixins **/
@mixin block ($name, $type: null) {
	@at-root {
		@if $type != null {
			.#{$name}-#{$type} {
				@content
			}
		} @else {
			.#{$name} {
				@content
			}
		}
	}
}

@mixin element ($name) {
	@at-root {
		&__#{"" + $name} {
			@content
		}
	}
}

@mixin modifier ($name) {
	@at-root {
		&#{&}--#{"" + $name} {
			@content
		}
	}
}


/** Bootstrap Grid Mixins **/
// Grid system
//
// Generate semantic grid columns with these mixins.

// Centered container element
@mixin container-fixed($gutter: $grid-gutter-width) {
	margin-right: auto;
	margin-left: auto;
	padding-left:  calc($gutter / 2);
	padding-right: calc($gutter / 2);
	@include clearfix;
}

// Creates a wrapper for a series of columns
@mixin make-row($gutter: $grid-gutter-width) {
	margin-left:  calc($gutter / -2);
	margin-right: calc($gutter / -2);
	@include clearfix;
}

// Generate the extra small columns
@mixin make-xs-column($columns, $gutter: $grid-gutter-width) {
	@include borderbox();
	position: relative;
	float: left;
	width: percentage(calc($columns / $grid-columns));
	min-height: 1px;
	padding-left:  calc($gutter / 2);
	padding-right: calc($gutter / 2);
}
@mixin make-xs-column-offset($columns) {
	margin-left: percentage(calc($columns / $grid-columns));
}
@mixin make-xs-column-push($columns) {
	left: percentage(calc($columns / $grid-columns));
}
@mixin make-xs-column-pull($columns) {
	right: percentage(calc($columns / $grid-columns));
}

// Generate the small columns
@mixin make-sm-column($columns, $gutter: $grid-gutter-width) {
	@include borderbox();
	position: relative;
	min-height: 1px;
	padding-left:  calc($gutter / 2);
	padding-right: calc($gutter / 2);

	@media (min-width: $screen-sm-min) {
		float: left;
		width: percentage(calc($columns / $grid-columns));
	}
}
@mixin make-sm-column-offset($columns) {
	@media (min-width: $screen-sm-min) {
		margin-left: percentage(calc($columns / $grid-columns));
	}
}
@mixin make-sm-column-push($columns) {
	@media (min-width: $screen-sm-min) {
		left: percentage(calc($columns / $grid-columns));
	}
}
@mixin make-sm-column-pull($columns) {
	@media (min-width: $screen-sm-min) {
		right: percentage(calc($columns / $grid-columns));
	}
}

// Generate the medium columns
@mixin make-md-column($columns, $gutter: $grid-gutter-width) {
	@include borderbox();
	position: relative;
	min-height: 1px;
	padding-left:  calc($gutter / 2);
	padding-right: calc($gutter / 2);

	@media (min-width: $screen-md-min) {
		float: left;
		width: percentage(calc($columns / $grid-columns));
	}
}
@mixin make-md-column-offset($columns) {
	@media (min-width: $screen-md-min) {
		margin-left: percentage(calc($columns / $grid-columns));
	}
}
@mixin make-md-column-push($columns) {
	@media (min-width: $screen-md-min) {
		left: percentage(calc($columns / $grid-columns));
	}
}
@mixin make-md-column-pull($columns) {
	@media (min-width: $screen-md-min) {
		right: percentage(calc($columns / $grid-columns));
	}
}

// Generate the large columns
@mixin make-lg-column($columns, $gutter: $grid-gutter-width) {
	@include borderbox();
	position: relative;
	min-height: 1px;
	padding-left:  calc($gutter / 2);
	padding-right: calc($gutter / 2);

	@media (min-width: $screen-lg-min) {
		float: left;
		width: percentage(calc($columns / $grid-columns));
	}
}
@mixin make-lg-column-offset($columns) {
	@media (min-width: $screen-lg-min) {
		margin-left: percentage(calc($columns / $grid-columns));
	}
}
@mixin make-lg-column-push($columns) {
	@media (min-width: $screen-lg-min) {
		left: percentage(calc($columns / $grid-columns));
	}
}
@mixin make-lg-column-pull($columns) {
	@media (min-width: $screen-lg-min) {
		right: percentage(calc($columns / $grid-columns));
	}
}

@mixin make-grid-columns($i: 1, $list: ".grid__column--xs-#{$i}, .grid__column--sm-#{$i}, .grid__column--md-#{$i}, .grid__column--lg-#{$i}") {
	@for $i from (1 + 1) through $grid-columns {
		$list: "#{$list}, .grid__column--xs-#{$i}, .grid__column--sm-#{$i}, .grid__column--md-#{$i}, .grid__column--lg-#{$i}";
	}
	#{$list} {
		@include borderbox();
		position: relative;
		// Prevent columns from collapsing when empty
		min-height: 1px;
		// Inner gutter via padding
		padding-left:  calc($grid-gutter-width / 2);
		padding-right: calc($grid-gutter-width / 2);
	}
}


// [converter] This is defined recursively in LESS, but Sass supports real loops
@mixin float-grid-columns($class, $i: 1, $list: ".grid__column--#{$class}-#{$i}") {
	@for $i from (1 + 1) through $grid-columns {
		$list: "#{$list}, .grid__column--#{$class}-#{$i}";
	}
	#{$list} {
		float: left;
	}
}


@mixin calc-grid-column($index, $class, $type) {
	@if ($type == width) and ($index > 0) {
		.grid__column--#{$class}-#{$index} {
			width: percentage(calc($index / $grid-columns));
		}
	}
	@if ($type == push) and ($index > 0) {
		.grid__column--#{$class}-push-#{$index} {
			left: percentage(calc($index / $grid-columns));
		}
	}
	@if ($type == push) and ($index == 0) {
		.grid__column--#{$class}-push-0 {
			left: auto;
		}
	}
	@if ($type == pull) and ($index > 0) {
		.grid__column--#{$class}-pull-#{$index} {
			right: percentage(calc($index / $grid-columns));
		}
	}
	@if ($type == pull) and ($index == 0) {
		.grid__column--#{$class}-pull-0 {
			right: auto;
		}
	}
	@if ($type == offset) {
		.grid__column--#{$class}-offset-#{$index} {
			margin-left: percentage(calc($index / $grid-columns));
		}
	}
}

// [converter] This is defined recursively in LESS, but Sass supports real loops
@mixin loop-grid-columns($columns, $class, $type) {
	@for $i from 0 through $columns {
		@include calc-grid-column($i, $class, $type);
	}
}


// Create grid for specific class
@mixin make-grid($class) {
	//@include float-grid-columns($class);
	@include loop-grid-columns($grid-columns, $class, width);
	@include loop-grid-columns($grid-columns, $class, pull);
	@include loop-grid-columns($grid-columns, $class, push);
	@include loop-grid-columns($grid-columns, $class, offset);
}

// Responsive utilities

//
// More easily include all the states for responsive-utilities.less.
// [converter] $parent hack
@mixin responsive-visibility($parent) {
	#{$parent} {
		display: block !important;
	}
	table#{$parent}  { display: table !important; }
	tr#{$parent}     { display: table-row !important; }
	th#{$parent},
	td#{$parent}     { display: table-cell !important; }
}

// [converter] $parent hack
@mixin responsive-invisibility($parent) {
	#{$parent} {
		display: none !important;
	}
}

@mixin fontSize($px, $lineHeight: false, $faktor: 4) {
	font-size: $px + 'px';
	font-size: calc($px / $body--base-font-size)  + rem;

	@if $lineHeight == true{
		line-height: $px + $faktor + 'px';
		line-height: 1.4;
	}
}

@mixin fontAntiAliasing(){
	-webkit-font-smoothing:antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@mixin opacity($opacity) {
	opacity: $opacity;
	$opacity-ie: $opacity * 100;
	filter: alpha(opacity=$opacity-ie); //IE8
}

@mixin transform($transform) {
	@include prefixer(transform, $transform);
}

@mixin transition($transition...) {
	@include prefixer(transition, $transition);
}

@mixin cleanAppearance() {
	@include prefixer(appearance, none);
}

@mixin borderBoxSizing() {
	@include prefixer(box-sizing, border-box);
}