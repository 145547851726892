// Print utilities
//
// Media queries are placed on the inside to be mixin-friendly.

// Note: Deprecated .visible-print as of v3.2.0

@include responsive-invisibility('.visible-print');

@media print {
	@include responsive-visibility('.visible-print');
}
.visible-print-block {
	display: none !important;

	@media print {
		display: block !important;
	}
}
.visible-print-inline {
	display: none !important;

	@media print {
		display: inline !important;
	}
}
.visible-print-inline-block {
	display: none !important;

	@media print {
		display: inline-block !important;
	}
}

@media print {
	@include responsive-invisibility('.hidden-print');
}
