$headline-1--font-family: $body--base-font-family !default;
$headline-1--font-size: 30 !default;
$headline-1--font-color: $dark-grey !default;
$headline-1--font-weight: bold !default;
$headline-1--text-transform: none !default;
$headline-1--margin-bottom: 20px !default;

h1,
.h1-style h2,
.h1-style h3,
.h1-style h4 {
	@include fontSize($headline-1--font-size, true, 6);

	color: $headline-1--font-color;
	margin-bottom: $headline-1--margin-bottom;
	text-transform: $headline-1--text-transform;
	font-weight: $headline-1--font-weight;
	font-family: $headline-1--font-family;

	@media (max-width: $sm){
		@include fontSize(($headline-1--font-size - 35), true, 6);
	}
}

$headline-2--font-family: $body--base-font-family !default;
$headline-2--font-size: 30 !default;
$headline-2--font-color: $dark-grey !default;
$headline-2--text-transform: none !default;
$headline-2--margin-bottom: 20px !default;

h2,
.h2-style h1,
.h2-style h3,
.h2-style h4 {
	@include fontSize($headline-2--font-size, true);

	color: $headline-2--font-color;
	margin-bottom: $headline-2--margin-bottom;
	text-transform: $headline-2--text-transform;
	font-family: $headline-2--font-family;

	@media (max-width: $sm){
		@include fontSize(($headline-2--font-size - 7), true);
	}
}

$headline-3--font-family: $body--base-font-family !default;
$headline-3--font-size: 20 !default;
$headline-3--font-color: $dark-grey !default;
$headline-3--font-weight: bold !default;
$headline-3--text-transform: none !default;
$headline-3--margin-bottom: 20px !default;

h3,
.h3-style h1,
.h3-style h2,
.h3-style h4 {
	@include fontSize($headline-3--font-size, true, 8);

	color: $headline-3--font-color;
	margin-bottom: $headline-3--margin-bottom;
	text-transform: $headline-3--text-transform;
	font-weight: $headline-3--font-weight;
	font-family: $headline-3--font-family;

	@media (max-width: $sm){
		@include fontSize($headline-3--font-size - 2, true, 8);
	}
}

$headline-4--font-family: $body--base-font-family !default;
$headline-4--font-size: 18 !default;
$headline-4--font-color: $dark-grey !default;
$headline-4--font-weight: bold !default;
$headline-4--text-transform: none !default;
$headline-4--margin-bottom: 20px !default;

h4,
.h4-style h1,
.h4-style h2,
.h4-style h3 {
	@include fontSize($headline-4--font-size, true);

	color: $headline-4--font-color;
	margin-bottom: $headline-4--margin-bottom;
	text-transform: $headline-4--text-transform;
	font-weight: $headline-4--font-weight;
	font-family: $headline-4--font-family;
}

$section-style--border-bottom: 6px solid $green-color !default;
$section-style--margin-bottom: 50px !default;
$section-style--margin-top: 10px !default;

.section-style h1, .section-style h2,.section-style h3 {
	@extend h1;

	display: inline-block;
	border-bottom: $section-style--border-bottom;
	margin-bottom: $section-style--margin-bottom;
	margin-top: $section-style--margin-top;

	@media (max-width: $sm){
		@include fontSize(($headline-1--font-size - 40), true, 6);
		margin-top: 0px;
		margin-bottom: 20px;
	}
}

.page-title h1, .page-title h2, .page-title h3 {
	@extend h1;
	font-weight: normal;
}

.reference-title-white h1, .page-title h2, .page-title h3 {
	@extend h1;
	margin-top: 150px;
	color: $white-color;

	@media (max-width: $sm){
		margin-top: 0px;
	}
}

.reference-title-black h1, .page-title h2, .page-title h3 {
	@extend h1;
	margin-top: 150px;

	@media (max-width: $sm){
		margin-top: 0px;
	}
}

.h-inherit-color {
	h1,h2,h3,h4,h5 {
		color: inherit;
	}
}
