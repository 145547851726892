$nav-button__background-color: $green-color !default;
$nav-button__color: $white-color !default;
$nav-button__background-color--open: $dark-green-color !default;
$nav-button__color--open: $white-color !default;
$nav-button__before--font-size: 32 !default;
$nav-button--padding-top: 10px !default;
$nav-button--padding-bottom: 10px !default;
$nav-button--padding-left: 15px !default;
$nav-button--padding-right: 15px !default;

@include block(nav-button){
	display: none;
	position: absolute;
	right: 20px;
	top: 20px;
	z-index: 21;
	background-color: $nav-button__background-color;
	color: $nav-button__color;
	padding: $nav-button--padding-top $nav-button--padding-right $nav-button--padding-bottom $nav-button--padding-left;

	&:hover {
		cursor: pointer;
	}

	&:before{
		@extend .mobilistics-icon;
		@include fontSize($nav-button__before--font-size);
		display: inline-block;
		content: $icon-bars;

		@media (max-width: $sm){
			@include fontSize(22);
		}
	}

	@include modifier(open){
		background-color: $nav-button__background-color--open;
		color: $nav-button__color--open;

		&:before{
			@extend .mobilistics-icon;
			@include fontSize($nav-button__before--font-size);
			display: inline-block;
			content: $icon-close;

			@media (max-width: $sm){
				@include fontSize(22);
			}
		}
	}

	@media (max-width: $md){
		display: block;
	}

}
