$select__box--after-font-size: 18 !default;
$select__box--after-width: 49px !default;
$select__box--after-height: 47px !default;
$select__box--after-line-height: 48px !default;
$select__box--after-background: #000 !default;
$select__box--after-color: $green-color !default;
$select__box--background-color: #fff !default;
$select-field--disabled-opacity: 0.5 !default;
$select-field--breakpoint-small: $sm !default;
$select__box--after-content: $icon-angle-down !default;
$select__box--font-color: #000 !default;
$select__box--border-radius: none !default;
$select__box--after-border-radius: none !default;
@include block(select-field) {
	display: block;
	margin-bottom: 5px;
	@include modifier(no-margin-bottom) {
		margin-bottom: 0;
		@media (max-width: $select-field--breakpoint-small) {
			margin-bottom: 5px;
		}
	}
	@include modifier(disabled) {
		@include opacity($select-field--disabled-opacity);
		pointer-events: none !important;
	}
	@include element(label) {
		display: none;
	}
	@include element(box) {
		height: 51px;
		position: relative;
		cursor: pointer;
		background: $select__box--background-color;
		border-radius: $select__box--border-radius;
		width: 99.7%;
		-webkit-appearance: none;
		border-radius: 0;
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;

		@media (max-width: $lg){
			width: 100%;
		}

		&:after {
			@extend .mobilistics-icon;
			@include fontSize($select__box--after-font-size);
			background-color: $white-color;
			display: block;
			content: $select__box--after-content;
			width: $select__box--after-width;
			height: $select__box--after-height;
			position: absolute;
			top: 2px;
			right: 1px;
			bottom: 0;
			text-align: center;
			line-height: $select__box--after-line-height;
			border-radius: $select__box--after-border-radius;
			color: $select__box--after-color;
			pointer-events: none;
			-moz-box-sizing: border-box;
			-webkit-box-sizing: border-box;
			box-sizing: border-box;
		}
		select {
			cursor: pointer;
			margin-bottom: 0;
			background: none;
			color: $select__box--font-color;
			padding-left: 5px;
		}
	}
}