$navigation--padding-left: 0px !default;
$navigation__list-item-padding-top: 10px !default;
$navigation__list-item-padding-bottom: 10px !default;
$navigation__list-item--border-bottom: 1px solid $white-color !default;
$navigation__list-item--font-size: 16 !default;

@include block(navigation){
	background-color: $green-color;
	padding: 15px;
	margin: auto;
	max-width: 1400px;
	width: 100%;
	box-sizing: border-box;
	position: absolute;
	z-index: 4;
	left: 0;
	right: 0;
	top: $logo--height;

	@media (max-width: $md){
		display: none;
		top: 0;
		bottom: 0;
		left: 15vw;
		padding: 15px 0;
		overflow: scroll;
	}

	@include element(list){
		list-style: none;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0;
		margin: auto;

		@media (max-width: $md){
			flex-direction: column;
			justify-content: flex-start;
			max-width: inherit;
			align-items: flex-start;
		}
	}

	@include element(sublist){
		display: none;
		background-color: $dark-green-color;
		position: absolute;
		top: 40px; // li element width in best case
		left: 0;
		list-style: none;
		padding: 15px 0;
		margin: 0;
		z-index: 5;

		&:hover{
			display: block;
		}

		li:hover{
			background-color: $green-color;
		}

		@media (max-width: $md){
			display: block;
			position: initial;
			background-color: $green-color;
			padding: 0 0 0 15px;
		}
	}

	@include element(list-item){
		position: relative;

		a{
			@include fontSize($navigation__list-item--font-size);
			text-decoration: none;
			display: inline-block;
			color: $white-color;
			white-space: nowrap;
			padding: 10px 15px;
		}

		&:hover{
			background-color: $dark-green-color;
		}

		&:hover .navigation__sublist{
			display: block;
		}

		@include modifier(current){
			> a{
				text-decoration: underline;
			}
		}

		@include modifier(active){
			> a{
				text-decoration: underline;
			}
		}

		@include modifier(home){
			a:before{
				display: inline-block;
				font-family: 'mobilistics-icons';
				font-size: 1.3rem;
				content: $icon-home;
			}
		}

		// to be clean change position in tree to li
		&--level-1{
			text-transform: uppercase;
		}

		@media (max-width: $md){
			position: initial;

			&:hover{
				background-color: transparent;
			}
		}
	}

	@include modifier(open){
		display: block;
	}
}
