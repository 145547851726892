$sm: 600px !default;
$md: 1024px !default;
$lg: 1200px !default;

// Small screen / tablet
$screen-sm-min: $sm !default;

// Medium screen / desktop
$screen-md-min: $md !default;

// Large screen / wide desktop
$screen-lg-min: $lg !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm-min - 1) !default;
$screen-sm-max: ($screen-md-min - 1) !default;
$screen-md-max: ($screen-lg-min - 1) !default;


// Grid Gutter / Grid Columns
$grid-gutter-width: 30px !default;
$grid-columns: 12 !default;

// Small screen / tablet
$container-tablet:             ($screen-sm-min + $grid-gutter-width) !default;
$container-sm:                 $container-tablet !default;

// Medium screen / desktop
$container-desktop:            ($screen-md-min + $grid-gutter-width) !default;
$container-md:                 $container-desktop !default;

// Large screen / wide desktop
$container-large-desktop:      ($screen-lg-min + $grid-gutter-width) !default;
$container-lg:                 $container-large-desktop !default;





// Container widths
//
// Set the container width, and override it for fixed navbars in media queries.

.container {
	@include borderbox();
	@include container-fixed;

	@media (min-width: $screen-sm-min) {
		width: $container-sm - $grid-gutter-width;
	}
	@media (min-width: $screen-md-min) {
		width: $container-md - $grid-gutter-width;
	}
	@media (min-width: $screen-lg-min) {
		width: $container-lg - $grid-gutter-width;
	}
}


// Fluid container
//
// Utilizes the mixin meant for fixed width containers, but without any defined
// width for fluid, full width layouts.

.container-fluid {
	@include container-fixed;
}


// Row
//
// Rows contain and clear the floats of your columns.

@include block(grid){
	@include make-row;

	@include element(column){
		float: left;
	}
}

// Columns
//
// Common styles for small and large grid columns

@include make-grid-columns;


// Extra small grid
//
// Columns, offsets, pushes, and pulls for extra small devices like
// smartphones.

@include make-grid(xs);


// Small grid
//
// Columns, offsets, pushes, and pulls for the small device range, from phones
// to tablets.

@media (min-width: $screen-sm-min) {
	@include make-grid(sm);
}


// Medium grid
//
// Columns, offsets, pushes, and pulls for the desktop device range.

@media (min-width: $screen-md-min) {
	@include make-grid(md);
}


// Large grid
//
// Columns, offsets, pushes, and pulls for the large desktop device range.

@media (min-width: $screen-lg-min) {
	@include make-grid(lg);
}


@media (max-width: $screen-xs-max) {
	@include responsive-invisibility('.hidden-xs');
}

@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
	@include responsive-invisibility('.hidden-sm');
}

@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
	@include responsive-invisibility('.hidden-md');
}

@media (min-width: $screen-lg-min) {
	@include responsive-invisibility('.hidden-lg');
}