@include block(console-button) {
	float: right;
	border: 1px solid white;

	@media (max-width: $sm) {
		font-size: 0.8rem;
	}

	@include element(wrapper) {
		position: fixed;
		z-index: 5;
		max-width: 1400px;
		left: 0;
		right: 0;
		top: 0;
		margin: auto;

		@media (max-width: $md){
			top: auto;
			right: auto;
			left: 0;
			bottom: 0;
		}
	}
}
