$ul--indent: 0px !default;
$ul--margin-bottom: 20px !default;
$ul--padding-left: 30px !default;
$ul--list-style: disc !default;
$ul--nested-ul-margin-bottom: 0px !default;
$ul--font-weight: normal !default;

$ul-list__item--font-size: $body--base-font-size !default;

ul{
	padding-left: $ul--padding-left;
	margin-left: $ul--indent;
	margin-bottom: $ul--margin-bottom;
	list-style: $ul--list-style;
	font-weight: $ul--font-weight;

	&.no-bullets{
		padding-left: 0;
		margin-left: 0;
		list-style: none;
	}

	li{
		@include fontSize($ul-list__item--font-size, true, 6);
	}

	ul {
		margin-bottom: $ul--nested-ul-margin-bottom;
	}

}


$ol--indent: 0px !default;
$ol--margin-bottom: 20px !default;
$ol--padding-left: 30px !default;
$ol--nested-ol-margin-bottom: 0px !default;
$ol--font-weight: normal !default;

$ol-list__item--font-size: $body--base-font-size !default;

ol {
	padding-left: $ol--padding-left;
	margin-left: $ol--indent;
	margin-bottom: $ol--margin-bottom;
	list-style-type: decimal;
	font-weight: $ul--font-weight;

	li{
		@include fontSize($ol-list__item--font-size, true, 6);
	}

	ol {
		margin-bottom: $ol--nested-ol-margin-bottom;
	}

}