$checkbox--font-size: 25 !default;
$checkbox--width: 25px !default;
$checkbox--height: $checkbox--width !default;
$checkbox--background-color: $white-color !default;
$checkbox--color: $black-color !default;
$checkbox--margin-bottom: 15px !default;
$checkbox--margin-right: 5px !default;
$checkbox--margin-top: 3px * -1 !default;

$checkbox--icon: $icon-check !default;

$checkbox__label--font-size: 18 !default;
$checkbox__label--color: $black-color !default;
$checkbox__label--margin-right: 10px !default;
$checkbox__label--margin-bottom: 0px !default;
$checkbox__label--line-height: 1.5 !default;

$checkbox__after--font-family: 'mobilistics-icons' !default;
$checkbox__after--font-size: 18 !default;
$checkbox__after--line-height: 35px !default;
$checkbox__after--top: -1px !default;
$checkbox__after--left: 0px !default;


.checkbox {
	position: relative;
	display: block;
	margin-bottom: $checkbox--margin-bottom;

	input[type=checkbox] {
		display: none;
		&:checked + label:after {
			content: $checkbox--icon;
		}
	}
	label {
		@include fontSize($checkbox__label--font-size);
		display: inline-block;
		cursor: pointer;
		color: $checkbox__label--color;
		margin-right: $checkbox__label--margin-right;
		margin-bottom: $checkbox__label--margin-bottom;
		line-height: $checkbox__label--line-height;

		&:before {
			@include borderbox();
			content: "";
			position: relative;
			display: inline-block;
			width: $checkbox--width;
			height: $checkbox--height;
			background-color: $checkbox--background-color;
			color: $checkbox--color;
			vertical-align: middle;
			text-align: center;
			border-radius: 0;
			margin-right: $checkbox--margin-right;
			margin-top: $checkbox--margin-top;
		}

		&:after {
			@include fontSize($checkbox__after--font-size);
			content: "";
			position: absolute;
			font-family: $checkbox__after--font-family;
			top: $checkbox__after--top;
			left: $checkbox__after--left;
			display: block;
			width: $checkbox--width;
			height: $checkbox__after--line-height;
			line-height: $checkbox__after--line-height;
			color: $checkbox--color;
			text-align: center;
			z-index: 1000;
		}
	}
}