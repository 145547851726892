$hr--color: #000000 !default;
$hr--height: 1px !default;
$hr--margin-bottom: 10px !default;
$hr--border-size: 1px !default;

hr {
	background: $hr--color;
	border: $hr--border-size;
	height: $hr--height;
	margin-bottom: $hr--margin-bottom;
}