$section--grey-background: $grey-color !default;
$section--white-background: $white-color !default;
$section--green-background: $green-color !default;
$section--padding-bottom: 60px !default;
$section__actionbutton__link--background-color: transparent !default;
$section__actionbutton__link--border: 2px solid $white-color !default;

@include block(section) {
	position: relative;
	background-color: $white-color;

	@include modifier(bgimage){
		background-size: cover;
		background-color: rgb(185, 185, 185);
	}

	@include modifier(waves){
		padding-bottom: $section--padding-bottom;
	}

	@include modifier(grey-background) {
		background-color: $section--grey-background;
	}

	@include modifier(white-background) {
		background-color: $section--white-background;
	}

	@include modifier(green-background) {
		background-color: $section--green-background;

		.section-style h1, .section-style h2, .section-style h3 {
			border-bottom: 6px solid $white-color;
		}

		* {
			color: $white-color;
		}

		.actionbutton__link {
			background-color: $section__actionbutton__link--background-color;
			border: $section__actionbutton__link--border;

			&:hover {
				background-color: $white-color;
				color: $green-color;
			}
		}
	}

	@include element(waves) {
		//position: relative;
		//padding-bottom: $section--padding-bottom * 2;
		position: absolute;
		bottom: -1px;
		left: 0;
		right: 0;
		content: '';
		height: 90px;

		@media (max-width: 1135px){
			background-size: cover;
		}

		@include modifier(green) {
			background: url(../Images/waves.svg) repeat-x;
			background-position: center top;
			background-size: 1132px 90px;
		}

		@include modifier(grey) {
			background: url(../Images/waves-grey.svg) repeat-x;
			background-position: center top;
			background-size: 1132px 90px;
		}

		@include modifier(white) {
			background: url(../Images/waves-white.svg) repeat-x;
			background-position: center top;
			background-size: 1132px 90px;
		}
	}

	@include modifier(subpage-image){
		min-height: 550px;

		@media (max-width: $sm){
			min-height: 150px;
		}
	}
}

@include block(team-section){
	.grid__column{
		.neos-contentcollection{
			.neos-nodetypes-image{
				text-align: center;
			}
		}
	}
}
