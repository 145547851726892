$font-weight--bold: 700 !default;
$font-weight--semibold: 600 !default;
$font-weight--light: 300 !default;
$font-weight--normal: 400 !default;

@mixin font-face($weight, $filename) {
	@font-face {
		font-family: 'Titillium Web';
		font-style: normal;
		font-weight: $weight;
		font-display: swap;
		src:
			local('Titillium Web'),
			url('../Fonts/Titillium_Web/#{$filename}.woff2') format('woff2'),
			url('../Fonts/Titillium_Web/#{$filename}.woff') format('woff');
	}
}

// Font-Faces
@include font-face($font-weight--light, 'titillium-web-v7-latin-300');
@include font-face($font-weight--normal, 'titillium-web-v7-latin-regular');
@include font-face($font-weight--semibold, 'titillium-web-v7-latin-600');
@include font-face($font-weight--bold, 'titillium-web-v7-latin-700');
