$alert--padding: 10px !default;
$alert--margin-bottom: 10px !default;
$alert--background: #f1f1f1 !default;
$alert--font-color: #000 !default;

$alert-variants:(success, #dff0d8, #3c763d),
		(info, #d9edf7, #31708f),
		(warning, #fcf8e3, #8a6d3b),
		(danger, #f2dede, #a94442) !default;

@include block(alert){
	@include borderbox();
	display: block;
	background: $alert--background;
	color: $alert--font-color;
	padding: $alert--padding;
	border: 1px solid $alert--font-color;
	margin-bottom: $alert--margin-bottom;

	&:last-child{
		margin-right: 0px;
	}

	*:last-child{
		margin-bottom: 0;
	}

	@each $modifiername, $background, $font-color in $alert-variants {
		@include modifier(#{$modifiername}){
			background: $background;
			background: rgba($background, 0.6);
			color: $font-color;
			border-color: $background;
		}
	}
}