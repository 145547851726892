$actionbutton--margin-top: 50px !default;
$actionbutton--hover--background-color: rgba($green-color, 0.7) !default;
$actionbutton__link--text-color: $white-color !default;
$actionbutton__link--background-color: $green-color !default;
$actionbutton__link--padding-top: 10px !default;
$actionbutton__link--padding-bottom: 10px !default;
$actionbutton__link--padding-right: 30px !default;
$actionbutton__link--padding-left: 30px !default;

@include block(actionbutton){
	margin-top: $actionbutton--margin-top;

	@media(max-width: $md){
		margin-bottom: 30px;
	}

	a {
		text-decoration: none;

		&:hover {
			color: $white-color;
			background-color: $actionbutton--hover--background-color;
		}
	}

	@include element(link){
		color: $actionbutton__link--text-color;
		background-color: $actionbutton__link--background-color;
		padding-top: $actionbutton__link--padding-top;
		padding-bottom: $actionbutton__link--padding-bottom;
		padding-left: $actionbutton__link--padding-left;
		padding-right: $actionbutton__link--padding-right;
	}

	@include modifier(alignment-center){
		text-align: center;
	}

	@include modifier(alignment-right){
		text-align: right;
	}

	@include modifier(alignment-left){
		text-align: left;
	}
}