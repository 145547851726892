$base--font-family: Helvetica, Arial, sans-serif !default;
$base--secondary-font-family: Times, sans-serif !default;

$body--base-font-size: 16 !default;
$body--base-font-color: #000000 !default;
$body--base-font-family: Helvetica, Arial, sans-serif !default;

body{
	@include fontSize($body--base-font-size);
	font-family: $body--base-font-family;
	color: $body--base-font-color;
	overflow-x: hidden;
}

html{
	font-size: 100%;
	@media (max-width: $sm){
		font-size: 120%;
	}
}

.htmlScrollFix {
	overflow: hidden;

	height: 100%;
	margin: 0;
	padding: 0;
	border: none;
}

.bodyScrollFix {
	overflow: hidden;
	position: relative;

	box-sizing: border-box;
	margin: 0;
	height: 100%;
}
