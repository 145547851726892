$contactform--margin-top: 80px !default;

$contactform__text-font-size: 25 !default;

$contactform__textfield--font-size: 18 !default;
$contactform__textfield--padding-left: 10px !default;
$contactform__textfield--margin-bottom: 5px !default;
$contactform__textfield--color: $dark-grey !default;

$contactform__dropdown-wrapper--color: $form-textcolor !default;

$contactform__dropdown-wrapper-after--font-size: 18 !default;
$contactform__dropdown-wrapper-after--color: $green-color !default;

$contactform__dropdown--border-color: $white-color !default;

$contactform__inputfield--font-size: 18 !default;
$contactform__inputfield--padding-left: 10px !default;
$contactform__inputfield--margin-bottom: 15px !default;

$contactform__label--font-size: 18 !default;

$contactform__actionbutton--font-size: 18 !default;
$contactform__actionbutton--background-color: $green-color !default;
$contactform__actionbutton--padding-top: 10px !default;
$contactform__actionbutton--padding-bottom: 10px !default;
$contactform__actionbutton--padding-right: 30px !default;
$contactform__actionbutton--padding-left: 30px !default;
$contactform__actionbutton--margin-top: 20px !default;
$contactform__actionbutton--border-color: $white-color !default;

$contactform__error--border: 3px solid red !default;

@include block(contactform) {
	margin-top: $contactform--margin-top;

	@media (max-width: $sm) {
		margin-top: 20px;
	}

	@include element(text) {
		p {
			@include fontSize($contactform__text-font-size);

			@media (max-width: $sm) {
				@include fontSize($contactform__text-font-size - 10);
			}
		}
	}

	@include element(textfield) {
		@include fontSize($contactform__textfield--font-size);
		color: $contactform__textfield--color !important;
		padding-left: $contactform__textfield--padding-left;
		margin-bottom: $contactform__textfield--margin-bottom;
		width: 49.5%;
		height: 51px;
		border: none;
		-webkit-appearance: none;
		border-radius: 0;

		@media (max-width: $lg) {
			width: 100%;
		}
	}

	@include element(dropdown) {
		@include fontSize($contactform__dropdown-wrapper-after--font-size);
		color: $contactform__dropdown-wrapper--color !important;
		height: 51px;
		width: 100%;
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		border-color: $contactform__dropdown--border-color;

		option {
			color: $black-color !important;
		}

		@media (max-width: $sm) {
			width: 100% !important;
		}
	}

	@include element(inputfield) {
		@include fontSize($contactform__inputfield--font-size);
		color: $contactform__textfield--color !important;
		padding-left: $contactform__inputfield--padding-left;
		margin-bottom: $contactform__inputfield--margin-bottom;
		width: 100%;
		height: 150px;
		border: none;
		-webkit-appearance: none;
		border-radius: 0;
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;

		@media (max-width: $lg) {
			width: 100%;
		}
	}

	@include element(checkbox) {
		@include fontSize($contactform__label--font-size);
	}

	@include element(actionbutton) {
		.form-navigation {
			ul {
				padding-left: 0px;
				li {
					list-style-type: none;

					&:after {
						display: none;
					}

					.btn {
						@include fontSize($contactform__actionbutton--font-size);
						background-color: $contactform__actionbutton--background-color;
						padding-top: $contactform__actionbutton--padding-top;
						padding-bottom: $contactform__actionbutton--padding-bottom;
						padding-left: $contactform__actionbutton--padding-left;
						padding-right: $contactform__actionbutton--padding-right;
						margin-top: $contactform__actionbutton--margin-top;
						border: 2px solid $contactform__actionbutton--border-color;

						&:hover {
							background-color: $white-color;
							color: $green-color;
							cursor: pointer;
						}
					}
				}
			}
		}
	}
}

.error {
	border: $contactform__error--border;
}

input[type="text"] {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

#contact-form-E-Mail,
#contact-form-Telefon {
	width: 100%;

	@media (max-width: $lg) {
		width: 100%;
	}
}
