/** Base Font Familys **/
$base--font-family: "Titillium Web", sans-serif;
$base--secondary-font-family: Helvetica, Arial, sans-serif;

/** Body Variables **/
$body--base-font-family: $base--font-family;
$body--base-font-color: $dark-grey;

$headline-1--font-color: $dark-grey;
$headline-2--font-color: $dark-grey;
$headline-3--font-color: $dark-grey;
$headline-4--font-color: $dark-grey;

$headline-1--font-size: 65;
$headline-2--font-size: 25;
$headline-3--font-size: 18;

$headline-2--text-transform: uppercase;

/** Buttons **/
$button-colors:		("black", $black-color, $white-color),
		("white", $white-color, $black-color),
		("grey", $grey-color, $white-color),
		("green", $green-color, $white-color),
		("disabled", #dfdfdf, $grey-color);