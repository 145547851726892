$paragraph--font-size: $body--base-font-size !default;
$paragraph--margin-bottom: 20px !default;

$paragraph__small--font-size: 14 !default;

$sup--font-size: smaller !default;
$sub--font-size: smaller !default;

$abbr--font-weight: bold !default;


p {
	@include fontAntiAliasing();
	@include fontSize($paragraph--font-size, true, 6);
	margin-bottom: $paragraph--margin-bottom;

	a {
		text-decoration: none;
		color: $green-color;

		&:hover{
			text-decoration: underline;
			color: $green-color;
		}
	}

	@media (max-width: $sm){
		@include fontSize($paragraph--font-size - 2, true, 6);
	}

	small {
		@include fontSize($paragraph__small--font-size, true);
	}
}

strong,b{
	font-weight: $font-weight--bold;
}

em, i{
	font-style: italic;
}

sup {
	vertical-align: super;
	font-size: $sup--font-size;
}

sub {
	vertical-align: sub;
	font-size: $sub--font-size;
}

abbr {
	font-weight: $abbr--font-weight;
}

.text-align-center {
	text-align: center;
}
