$button--font-size: 16 !default;
$button--font-weight: normal !default;
$button--padding: 0px 10px !default;
$button--margin-bottom: 15px !default;
$button--line-height: 38px !default;
$button--background: $black-color !default;
$button--background-hover: lighten($button--background, 10) !default;

$button--color: $white-color !default;
$button--color-hover: $white-color !default;

$button--margin-right: 10px !default;

// name, color, text-color
$button-colors:		("black", $black-color, $white-color),
					("white", $white-color, $black-color),
					("grey", $grey-color, $white-color),
					("disabled", #dfdfdf, $grey-color)!default;

@include block(button) {
	@include borderbox();
	@include fontSize($button--font-size);
	@include fontAntiAliasing();
	display: table;
	background: $button--background;
	color: $button--color;
	padding: $button--padding;
	margin-bottom: $button--margin-bottom;
	cursor: pointer;
	border: none;
	text-decoration: none;
	height: $button--line-height;
	line-height: $button--line-height;
	font-weight: $button--font-weight;

	&:hover {
		background: $button--background-hover;
		color: $button--color-hover;
		text-decoration: none;
	}

	@include modifier(inline-block){
		display: inline-block;
	}

	@each $modifier, $background, $color in $button-colors {
		@include modifier(#{$modifier}){

			@if $modifier != 'disabled' {
				background: $background;
				color: $color;

				&:hover{

					@if $modifier == 'black' {
						background: lighten($background, 25);
					} @else {
						background: lighten($background, 5);
					}
				}

			}@else{
				background: lighten($background, 5);
				color: lighten($color, 25);
			}
		}
	}
}