@include block(newslist){
	@include element(item-container){
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
	}

	.actionbutton{
		text-align: center;
		margin-top: 20px;
	}
}

$newslistitem--margin-bottom: 30px !default;
$newslistitem--padding-bottom: 30px !default;
$newslistitem--border-bottom: 2px solid $green-color !default;
$newslistitem__teaser-image--margin-right: 1em !default;

$newslistitem__teaser-date--margin-bottom: 5px !default;
$newslistitem__teaser-date--font-size: 18 !default;

$newslistitem__pagination__li--padding-top: 10px !default;
$newslistitem__pagination__li--padding-bottom: 10px !default;
$newslistitem__pagination__li--padding-right: 20px !default;
$newslistitem__pagination__li--padding-left: 20px !default;

$newslistitem__more-link-color: $green-color !default;

@include block(newslistitem){
	display: block;
	text-decoration: none;
	padding: 25px;
	flex-basis: 28%;
	margin-bottom: 15px;
	background-color: rgba(255,255,255,0.35);

	@media (max-width: $lg){
		flex-basis: 27%;
	}

	@media (max-width: $md){
		flex-basis: 40%;
		margin-bottom: 10px;
	}

	@media (max-width: $sm){
		flex-basis: 90%;
		margin-bottom: 15px;
	}

	&:hover, &:active, &:focus{
		background-color: $white-color;
		-webkit-box-shadow: 0px 0px 20px -6px rgba(0,0,0,0.35);
		-moz-box-shadow: 0px 0px 20px -6px rgba(0,0,0,0.35);
		box-shadow: 0px 0px 20px -6px rgba(0,0,0,0.35);
		-webkit-transition: all 0.5s;
		transition: all 0.5s;

		/* Mögliche Umbrüche vorhersehen
		.newslistitem__headline{
			border-left: 4px solid $green-color;
			padding-left: 10px;
			-webkit-transition: all 0.2s;
			transition: all 0.2s;
		}
		*/
	}

	@include element(teaser-image){
		margin-bottom: 20px;
		height: 180px;

		img {
			display: block;
			margin: auto;
			max-width: 100%;
			max-height: 100%;
			width: auto;
			height: auto;
			position: relative;
			top: 50%;
			transform: translateY(-50%);
		}
	}

	@include element(teaser-date){
		font-weight: bold;

		p{
			@include fontSize($newslistitem__teaser-date--font-size);
			margin-bottom: $newslistitem__teaser-date--margin-bottom;

			@media (max-width: $sm){
				@include fontSize($newslistitem__teaser-date--font-size - 4, true, 6);
			}
		}
	}

	@include element(headline){
		color: $green-color;
	}

	@include element(pagination){
		list-style-type: none;
		padding-left: 0px;
		text-align: center;

		li{
			display: inline-block;

			&:hover{
				background-color: $green-color;
			}

			a{
				text-decoration: none;
				background-color: $grey-color;
				padding-top: $newslistitem__pagination__li--padding-top;
				padding-bottom: $newslistitem__pagination__li--padding-bottom;
				padding-left: $newslistitem__pagination__li--padding-left;
				padding-right: $newslistitem__pagination__li--padding-right;

				&:hover{
					color: $white-color;
					background-color: $green-color;
				}
			}
		}
	}
}


li.current{
	padding-top: $newslistitem__pagination__li--padding-top;
	padding-bottom: $newslistitem__pagination__li--padding-bottom;
	padding-left: $newslistitem__pagination__li--padding-left;
	padding-right: $newslistitem__pagination__li--padding-right;
	background-color: $green-color !important;
	color: $white-color !important;
}

li.next{
	position:relative;

	a{
		&:hover {

			&:after{
				color: $white-color;
				background-color: $green-color;
				bottom: -11px;
				left: 0px;
				cursor: pointer;
				padding: 11px 14px;
			}
		}

		&:after{
			@extend .mobilistics-icon;
			display: inline-block;
			content: $icon-chevron-right;
			position: absolute;
			bottom: 0px;
			left: 15px;
			cursor: pointer;
		}
	}
}

li.previous{
	position:relative;

	a{
		&:hover {

			&:after{
				color: $white-color;
				background-color: $green-color;
				bottom: -11px;
				left: 0px;
				cursor: pointer;
				padding: 11px 14px;
			}
		}

		&:after{
			@extend .mobilistics-icon;
			display: inline-block;
			content: $icon-chevron-left;
			position: absolute;
			bottom: 0px;
			left: 15px;
			cursor: pointer;
		}
	}
}
