$newslettercheck-contactform--margin-top: 5px !default;
$newslettercheck-contactform__textfield--margin-bottom: 15px !default;
$newslettercheck-contactform__checkbox--margin-bottom: 15px !default;
$newslettercheck-contactform__checkbox--margin-top: 15px !default;

@include block (newslettercheck){

	.contactform {
		margin-top: $newslettercheck-contactform--margin-top;

		.contactform__textfield{
			width: 100%;
			margin-bottom: $newslettercheck-contactform__textfield--margin-bottom;

			@media (max-width: $sm){
				width: 98%;
			}
		}

		.contactform__checkbox{
			margin-bottom: $newslettercheck-contactform__checkbox--margin-bottom;
			margin-top: $newslettercheck-contactform__checkbox--margin-top;

		}
	}
}