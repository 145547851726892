@include block(header){
	h1,h2 {
		margin-bottom: 0;
	}

	h1 {
		font-weight: 300;
	}

	.color-white {
		text-shadow: 0px 3px 10px rgba(0,0,0,0.25);
	}

	.color-dark-grey2 {
		text-shadow: 0px 3px 10px rgba(255,255,255,0.25);
	}

	.section {
		padding-top: calc(#{$logo--height} + 100px);

		@media (max-width: $md){
			padding-top: $logo--height-md;
		}

		@media (max-width: $sm){
			padding-top: $logo--height-sm;
		}
	}
}
