$image--alignment-left--margin-right: 1em !default;
$image--alignment-left--margin-bottom: 0.5em !default;
$image--alignment-left--margin-top: 0.5em !default;
$image--alignment-right--margin-left: 1em !default;
$image--alignment-right--margin-bottom: 0.5em !default;
$image--alignment-right--margin-top: 0.5em !default;

@include block(image){
	max-width: 50%;

	@include modifier(alignment-left){
		float: left;
		margin-right: $image--alignment-left--margin-right;
		margin-bottom: $image--alignment-left--margin-bottom;
		margin-top: $image--alignment-left--margin-top;
	}

	@include modifier(alignment-right){
		float: right;
		margin-left: $image--alignment-right--margin-left;
		margin-bottom: $image--alignment-right--margin-bottom;
		margin-top: $image--alignment-right--margin-top;
	}

	@include modifier(alignment-center){
		display: block;
		width: 100%;
		max-width: 100%;
		text-align: center;

		img {
			width: 70%;
		}
	}
}

img {
	max-width: 100%;
	height: auto;
}