$link--color: #000000 !default;
$link--hover-color: #333333 !default;
$link--text-decoration: underline !default;
$link--text-decoration--hover: none !default;

a {
	color: $link--color;
	text-decoration: $link--text-decoration;

	&:hover{
		color: $link--hover-color;
		text-decoration: $link--text-decoration--hover;
	}
}