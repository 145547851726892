$container--padding-top: 30px !default;
$container--padding-bottom: $section--padding-bottom;

@include block(container){
	padding-top: $container--padding-top;
	padding-bottom: $container--padding-bottom;

	@media (max-width: $sm){
		padding-top: 15px;
		padding-bottom: 40px;
		margin-top: -15px;
	}

	@include modifier(bg-image) {
		background-repeat: no-repeat;

		@media (max-width: $sm) {
			background: none !important;
		}
	}

	@include modifier(image-bottom-right){
		background-position: bottom right;
	}

	@include modifier(image-top-right){
		background-position: top right;
	}

	@include modifier(image-bottom-left){
		background-position: bottom left;
	}

	@include modifier(image-top-left){
		background-position: top left;
	}
}